import itiriri from 'itiriri';
import { HSUser } from 'server-openapi';

interface UserUtilStores {
  usersStore: ReadonlyMap<string, HSUser>;
}

export class UserUtils {
  private stores: UserUtilStores;
  constructor(apiStores: UserUtilStores) {
    this.stores = apiStores;
  }
  public getUserFullNameAndRoleFromUsername = (userLogin?: string | null) =>
    getUserFullNameAndRoleFromUsername(this.stores.usersStore, userLogin);
  public getUserFullNameAndRoleFromSubjectId = (subjectId?: string | null) =>
    getUserFullNameAndRoleFromSubjectId(this.stores.usersStore, subjectId);
  public getUserFullNameAndRoleFromUserOrEmailId = (email?: string | null, hsId?: number | null) =>
    getUserFullNameAndRoleFromUserOrEmailId(this.stores.usersStore, email, hsId);
}

function getUserFullNameAndRoleFromUsername(usersStore: ReadonlyMap<string, HSUser>, userLogin?: string | null): string {
  const user = itiriri(usersStore.values()).find((user) => user.login === userLogin);
  return user ? `${user.firstName} ${user.familyName}${getDesignationString(user?.designation)}` : userLogin ?? '';
}

function getUserFullNameAndRoleFromSubjectId(usersStore: ReadonlyMap<string, HSUser>, subjectId?: string | null): string {
  const user = itiriri(usersStore.values()).find((user) => user.subjectId === subjectId);
  return user ? `${user.firstName} ${user.familyName}${getDesignationString(user?.designation)}`: 'Unknown';
}

function getDesignationString(designation: string | null | undefined) {
  if (!designation) {
    return '';
  }
  const designationRole = designation.substr(designation.lastIndexOf(':') + 1);
  switch (designationRole) {
    case 'EnrolledNurse': {
      return ', Enrolled Nurse';
    }
    case 'PersonalCarer': {
      return ', Personal Carer';
    }
    case 'CarerNurse': {
      return ', Carer';
    }
    case 'VicCarer': {
      return ', Victorian Carer';
    }
    case 'Doctor': {
      return ', Doctor';
    }
    case 'RegisteredNurse': {
      return ', Registered Nurse';
    }
    case 'Administrator': {
      return ', Administrator';
    }
    case 'SyncAccount': {
      return ', Sync Account';
    }
    case 'FacilityManager': {
      return ', Facility Manager';
    }
    case 'MpsSupport': {
      return ', MPS Support';
    }
    case 'SystemAdmin': {
      return ', System Admin';
    }
    case 'AgencyNurse': {
      return ', Agency Nurse';
    }
    case 'DisabilitySupportWorker': {
      return ', Disability Support Worker';
    }    
    case 'Receptionist': {
      return ', Reporting';
    }
    case 'EndorsedEnrolledNurse': {
      return ', Agency EN';
    }
    case 'FacilityAdmin': {
      return ', Facility Admin';
    }
    case 'SecondChecker': {
      return ', Second Checker';
    }
    case 'BasicUserManagement': {
      return ', Basic User Management';
    }
    case 'AdvancedUserManagement': {
      return ', Advanced User Management';
    }
    default: {
      return '';
    }
  }
}

function getUserFullNameAndRoleFromUserOrEmailId(usersStore: ReadonlyMap<string, HSUser>, email?: string | null, hsId?: number | null) {
  let user: HSUser | undefined;
  if (hsId) {
    user = !!email ? itiriri(usersStore.values()).find((user) => user.hsId === hsId) : undefined;
  }
  if (!user && email) {
    user = !!email ? itiriri(usersStore.values()).find((user) => user.email === email) : undefined;
  }
  if (!user) {
    return user;
  }
  return `${user.firstName} ${user.familyName}${getDesignationString(user.designation)}`;
}
