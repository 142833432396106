import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import urljoin from 'url-join';
import { ExternalLink } from '../kit/ExternalLink';
import { Layout } from '../kit/Layout';
import { LinkButton } from '../kit/LinkButton';

interface IProps {
  menuRoutes: MenuRoute[];
}

export interface MenuRoute {
  route: string;
  label: string;
  externalUrl?: string;
}

export function PageMenuBar(props: IProps) {
  const { url } = useRouteMatch();

  return (
    <Container horizontal>
      {props.menuRoutes.map((mr, index) => (
        <NavigationButton
          first={index === 0}
          isExternal={mr.externalUrl !== undefined}
          to={mr.externalUrl ?? urljoin(url, mr.route)}
          label={mr.label}
          key={index}
        />
      ))}
    </Container>
  );
}

interface NavigationButtonProps {
  to: string;
  label?: string;
  first?: boolean;
  isExternal: boolean;
}

function NavigationButton(props: NavigationButtonProps) {
  const match = useRouteMatch({
    path: props.to,
    exact: true,
  });
  const { isExternal } = props;
  return (
    (isExternal && <StyledExternalLink to={props.to}>{props.label}</StyledExternalLink>) || (
      <NavButton id={props.first ? 'first' : undefined} to={props.to} selected={match?.isExact ?? false}>
        {props.label}
      </NavButton>
    )
  );
}

const NavButton = styled(LinkButton)<{ selected: boolean }>`
  background: ${(p) => (p.selected ? p.theme.body.background : p.theme.backgrounds.light.bg)};
  color: ${(p) => (p.selected ? p.theme.backgrounds.default.fg : p.theme.typography.defaults.color)};

  border-style: solid;
  border-right-width: 1px;
  border-color: ${(p) => p.theme.body.background};

  &#first {
    border-style: solid;
    border-left-width: 1px;
    border-color: ${(p) => p.theme.body.background};
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  background: ${(p) => p.theme.backgrounds.light.bg};
  color: ${(p) => p.theme.typography.defaults.color};

  border-style: solid;
  border-right-width: 1px;
  border-color: ${(p) => p.theme.body.background};

  &#first {
    border-style: solid;
    border-left-width: 1px;
    border-color: ${(p) => p.theme.body.background};
  }
`;

const Container = styled(Layout)`
  background-color: ${(p) => p.theme.backgrounds.light.bg};
  padding-left: 50px;

  & ${NavButton} {
    outline: none;
  }

  & ${StyledExternalLink} {
    outline: none;
  }
`;
