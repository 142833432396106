import React from 'react';
import {HSChartDose, HSDrug, HSPackedMedication, HSPackedPrnMedication, HSPatient} from 'server-openapi';
import { Layout } from '../../../../kit/Layout';
import { Image } from '../../../../kit/Image';
import styled from 'styled-components';
import { AdministrationWarningIcons, DrugWarningIcons } from '../medicationInformation/DrugDetails';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { DrugUtils } from '../../../../syncstream/utils/DrugUtils';
import { Text } from '../../../../kit/Text';
import { checkIfMedicationIsSelfAdministered } from '../../../../syncstream/utils/PackedPatientDayUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import {Tooltip} from "react-tooltip";

interface IProps {
  drug: HSDrug;
  packedMedication?: HSPackedMedication | HSPackedPrnMedication;
  facilityGroupId: number;
  patient: HSPatient;
}

export function DrugDetailLeftPanel(props: IProps) {
  const apiUtils = useApiUtils();
  const doseTimestamp = !!props.packedMedication ? getDoseTimestamp(props.packedMedication) : undefined;
  const chartItemDose = apiUtils.patients.getHSChartDoseFromPackedMedication(props.patient, props.packedMedication);

  return (
    <Layout gap={0.5}>
      {props.drug.imageUri && <DrugImage src={props.drug.imageUri} facilityGroupId={props.facilityGroupId} />}
      {!DrugUtils.drugHasNoWarning(props.drug) && (
        <DrugWarningsContainer>
          <DrugWarningIcons displayLabels drugWarnings={DrugUtils.getDrugWarnings(props.drug)} />
        </DrugWarningsContainer>
      )}
      <DrugDetailText weight={'bold'} size="large">
        {!!doseTimestamp && DateUtils.dateStringTo24HourTimeString(doseTimestamp)} {props.drug.name}{' '}
        {props.drug.formAbbreviation} {props.drug.strength}
      </DrugDetailText>
      <DrugDetailText>({props.drug.genericName})</DrugDetailText>
      <DrugDetailText>
        {props.packedMedication?.directions}{' '}
        {props.packedMedication?.route?.code && `[${props.packedMedication?.route?.code}]`}
      </DrugDetailText>
        {chartItemDose?.reasonForPrescribing && (
            <DrugDetailText>Reason for prescribing: {chartItemDose?.reasonForPrescribing}</DrugDetailText>
        )}
        {chartItemDose?.indications && <DrugDetailText>Indications: {chartItemDose?.indications}</DrugDetailText>}

      <Layout horizontal>
        <AdministrationWarningIcons
          administrationWarnings={DrugUtils.getAdministrationWarnings(
            props.drug,
            props.packedMedication,
            apiUtils.patients.getHSMedicationFromPackedMedication(props.patient, props.packedMedication),
            props.packedMedication ? checkIfMedicationIsSelfAdministered(props.packedMedication, props.patient) : false,
          )}
        />
      </Layout>
    </Layout>
  );
}

const DrugDetailText = styled(Text)`
  color: ${(p) => p.theme.backgrounds.default.fg};
`;

const DrugImage = styled(Image)`
  object-fit: cover;
  max-width: 100%;
  height: 150px;
`;

const DrugWarningsContainer = styled(Layout)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  width: fit-content;
  //padding: 5px;
`;

function getDoseTimestamp(packedMedication: HSPackedMedication | HSPackedPrnMedication) {
  if ('doseTimestamp' in packedMedication) {
    return packedMedication.doseTimestamp;
  }
}
