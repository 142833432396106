import * as H from 'history';
import React, { useState } from 'react';
import { BiSliderAlt } from 'react-icons/bi';
import { IoCaretForwardOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { HSDoseRound, HSFacilityGroup, ReasonCode } from 'server-openapi';
import styled, { useTheme } from 'styled-components';
import { useCurrentUser } from '../../../../core/authn/UserProvider';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Button } from '../../../../kit/Button';
import { SearchInput } from '../../../../kit/Forms/SearchInput';
import { Select as FormSelect } from '../../../../kit/Forms/Select';
import Select, { components, GroupBase, MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';

import { Grid } from '../../../../kit/Grid';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { useRoundSchedule } from '../../../Rounds/services/RoundScheduleProvider';
import {
  FilterDrugTypeControlled,
  FilterDrugTypeOther,
} from '../DashboardPage';
import {SelectWards} from "./SelectWards";
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";


interface IProps {
  facilityGroup?: HSFacilityGroup;
  searchQuery: string;
  onSearch: (query: string) => void;
  hasDosesInWindow: boolean;
  onSelectFacilities: (facilities: string[]) => void;
  selectedFacilityIds?: string[];
  filterDrugTypeControlled?: FilterDrugTypeControlled;
  filterDrugTypeOther?: FilterDrugTypeOther;
  onSelectDrugTypeControlled: (drugTypeControlled: FilterDrugTypeControlled) => void;
  onSelectDrugTypeOther: (drugTypeOther: FilterDrugTypeOther | undefined) => void;
  selectedDoseStatus?: ReasonCode;
  onSelectDoseStatus: (doseStatus: ReasonCode) => void;
}

const IconContainer = styled.h2`
  cursor: pointer;
  padding: 0.3em;
  color: ${(props) => props.theme.backgrounds.default.fg};
  margin: 0;
`;

const Container = styled.div`
  display: flex;
  padding: 0.5em 2em;
`;

const ResidentFilterContainer = styled(Grid)`
  grid-template-rows: min-content;
  button {
    padding: 0px;
  }
`;

function CreateRoundButton(props: {
  history: H.History<H.LocationState>;
  hasDosesInWindow: boolean;
  activeRound?: HSDoseRound;
  facilityGroupId?: number;
}): JSX.Element {
  const buttonText = props.activeRound ? 'Resume Round' : 'Create Round';
  const roundScheduleContext = useRoundSchedule();
  const groupPermissions = useGroupPermissions();
  const canStartRound = groupPermissions.canUndertakeMedicationRound;
  const canViewRound = groupPermissions.canViewMedicationRound;

  return (
    <Button
      data-testid="create-round-button"
      large
      onClick={() => {
        if (props.activeRound) {
          roundScheduleContext.set(props.activeRound);
          props.history.push(`/facility-group/${props.facilityGroupId}/rounds/${props.activeRound.clinicalSystemId}`);
          return;
        }
        if (!props.hasDosesInWindow) {
          // TODO: Do a pop-up
          alert('There are no residents with medication to administer within the round window');
        } else {
          props.history.push(`/facility-group/${props.facilityGroupId}/rounds/create`);
        }
      }}
      disabled={!canStartRound && !canViewRound}
      fullWidth
    >
      {buttonText} <IoCaretForwardOutline />
    </Button>
  );
}

export function DashboardMenu(props: IProps) {
  const user = useCurrentUser();
  const history = useHistory();
  const groupPermissions = useGroupPermissions();
  const [isFilterVisible, setFilterVisible] = useState(!!props.selectedFacilityIds || !!props.filterDrugTypeControlled || !!props.filterDrugTypeOther);
  const facilityUtils = useApiUtils().facilities;
  const roundUtils = useApiUtils().rounds;
  const facilityGroupUtils = useApiUtils().facilityGroups;
  const facilities = facilityUtils.fetchAllFacilities(props.facilityGroup);
  const wingLabel = props.facilityGroup?.hsId
    ? facilityGroupUtils.getFacilityUILabel(props.facilityGroup?.hsId)
    : 'wings';
    const residentLabel = props.facilityGroup?.hsId
    ? facilityGroupUtils.getResidentUILabel(props.facilityGroup.hsId)
    : 'Resident';

  const activeRounds = roundUtils
    /* eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain */
    .activeUserRounds(user.profile.sub, props.facilityGroup?.hsId!)
    .sort((l, r) => DateUtils.compareDateStringsDescending(l.createdAt, r.createdAt));
  const theme = useTheme();
  const convertMultiValueOptionToEnum = (option: MultiValue<any>): FilterDrugTypeOther | undefined => {
    if (option.length===0) return undefined;


    if (option.length===1) {
      return option[0].value;
    }

    return option.reduce((a, b) => {
      const aValue: FilterDrugTypeOther = a.value;
      const bValue: FilterDrugTypeOther = b.value;
      return aValue | bValue;
    });
  }

  const controlledDrugsTypeOptions = [
    { label: 'Controlled', value: FilterDrugTypeControlled.Controlled },
    { label: 'Non-controlled', value: FilterDrugTypeControlled.NonControlled }
  ];

  if (groupPermissions.canAdministerPackedControlledDrugs)
  {
    controlledDrugsTypeOptions.push({ label: 'Administrable', value: FilterDrugTypeControlled.Administrable });
  }


  const medicationTypeOptions = [
    { value: FilterDrugTypeOther.TimeCritical, label: "Time Critical" },
    { value: FilterDrugTypeOther.Insulin, label: "Insulin" },
  ];

  const allOptions:any[] = [{
      label: "Medication Type",
      options: medicationTypeOptions,
      value: -1
    }];

  return (
    <Container>
      <Grid cols={1} gap={1} style={{ flex: 1 }}>
        <ResidentFilterContainer cols={2} colsMobile={1} gap={1}>
          <CreateRoundButton
            history={history}
            hasDosesInWindow={props.hasDosesInWindow}
            facilityGroupId={props.facilityGroup?.hsId}
            activeRound={activeRounds[0]}
          />
          <SearchInput
            onChange={(_, v) => props.onSearch(v)}
            value={props.searchQuery}
            name="search"
            placeholder={`Search ${residentLabel}`}
          />
        </ResidentFilterContainer>
        {isFilterVisible && (
           <Grid colsTemplate='auto auto auto auto' gap={1} style={{ zIndex: 20}}>
             <FormSelect
                 data-testid="select-drugs"
                 fullWidth
                 placeholder="All Medications"
                 name="control"
                 options={controlledDrugsTypeOptions}
                 onChange={(_, v) => {
                   props.onSelectDrugTypeControlled(v);
                 }}
                 value={props.filterDrugTypeControlled}
             />
             <Select
                 aria-label="Filter By"
                 isMulti={true}
                 isClearable={true}
                 escapeClearsValue={true}
                 name={`FilterDrugTypeOther`}
                 placeholder={`Filter by: `}
                 options={allOptions}
                 onChange={(option, actionMeta) => {
                   const optionEnum = convertMultiValueOptionToEnum(option);
                   props.onSelectDrugTypeOther(
                     optionEnum
                   );
                 }}

                 components={{
                   Option: (props) => {
                     return (
                         <div>
                           <components.Option { ...props }>
                             <input type='checkbox' checked={props.isSelected} onChange={() => null}/>{' '}
                             <label>{props.label}</label>
                           </components.Option>
                         </div>
                     );
                   },
                 }}
                 defaultValue={medicationTypeOptions.filter(v =>
                     (props.filterDrugTypeOther ?
                         (v.value | FilterDrugTypeOther.TimeCritical) === props.filterDrugTypeOther ||
                         (v.value | FilterDrugTypeOther.Insulin) === props.filterDrugTypeOther : undefined))}
                 hideSelectedOptions={false}
                 closeMenuOnSelect={false}
                 styles={{
                   control: (provided) => ({ ...provided, width: '100%', height: '100%', zIndex:9999}),
                   menu: provided =>({...provided, zIndex:9999})
                 }}
             />

              <SelectWards wards={facilities} selectedWardIds={props.selectedFacilityIds} wingLabel={wingLabel}
                           selectionChanged={(facilityIds) => props.onSelectFacilities(facilityIds)} />
              <FormSelect
                  fullWidth
                  placeholder="All"
                  name="refused"
                  options={[
                    { label: 'Administered Late', value: ReasonCode.DosedLate },
                    { label: 'Refused', value: ReasonCode.Refused },
                    { label: 'Withheld', value: ReasonCode.Withheld },
                  ]}
                  value={props.selectedDoseStatus}
                  onChange={(_, value) => props.onSelectDoseStatus(value)}
              />
            </Grid>
        )}
      </Grid>
      <IconContainer onClick={() => setFilterVisible(!isFilterVisible)}>
        <BiSliderAlt />
      </IconContainer>
    </Container>
  );
}
