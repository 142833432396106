/* eslint-disable sonarjs/no-duplicate-string */
import { Logger } from '../core/logger/logger';
import { beginStopwatch } from '../core/utils/stopwatch';
import { Lock } from '../kit/Lock';
import {MemoryCache} from "../core/storage/MemoryCache";
import {
  hasBeenArchived,
  isCacheLoadInProgress, isSingleFacilityLoadInProgress,
  isSyncInProgress,
  isSyncRequired, lastSyncedAt, setCacheLoadInProgress, setHasBeenArchived, setSingleFacilityLoadInProgress,
  setSyncInProgress,
  setSyncRequired
} from '../context/AppStateProvider';
import {SyncFacilities} from "./SyncFacilities";
import {SyncCorporates} from "./SyncCorporates";
import {SyncFacilityGroups} from "./SyncFacilityGroups";
import {SyncRounds} from "./SyncRounds";
import {SyncDrugs} from "./SyncDrugs";
import {SyncDrugForms} from "./SyncDrugForms";
import {SyncDrugCategories} from "./SyncDrugCategories";
import {SyncTestResults} from "./SyncTestResults";
import {SyncSecondCheckSettings} from "./SyncSecondCheckSettings";
import {SyncPinUsers} from "./SyncPinUsers";
import {SyncNimAvailableDrugs} from "./SyncNimAvailableDrugs";
import {SyncFacilityGroupConfigurations} from "./SyncFacilityGroupConfigurations";
import {SyncPatients} from "./SyncPatients";
import {SyncPackedPatientDays} from "./SyncPackedPatientDays";
import {SyncPackedPatientPrnMedication} from "./SyncPackedPatientPrnMedication";
import {SyncOrders} from "./SyncOrders";
import {SyncPatchObservations} from "./SyncPatchObservations";
import {SyncUsers} from "./SyncUsers";
import {SyncSyringeDriverActivities} from "./SyncSyringeDriverActivity";
import {SyncTestResultTypes} from "./SyncTestResultTypes";
import {StoreConfigurationDto} from "../core/storage/StoreConfigurationDto";

export interface ISyncService {
  name: string;
  syncUp(): Promise<void>;
  syncDown(facilityGroupId?: string): Promise<void>;
  clear(): Promise<void>;
  hasQueuedData(): Promise<boolean>;
  isAllowed(canUserAccessMedication: boolean): boolean;
}

export interface IFacilityGroupSyncService extends ISyncService {
  load(facilityGroupId: string): Promise<void>;
  setEncryptionVersion(version: number): void;
  rewrite(): Promise<void>;
}
const logger = new Logger('SyncCenter');
const lock = new Lock();


export class SyncCenter {
  private interval?: NodeJS.Timer;
  private initialServices: ISyncService[] = [];

  // Services where the data from the store is to be filtered before loading into memory - e.g. patients
  private filteredServices: IFacilityGroupSyncService[] = [];

  // Services that are per facility group but that need to be resynced each time a facility group is selected.
  // These services that aren't changes based.  They are loaded once at login time.
  private singleFacilityGroupServices: IFacilityGroupSyncService[] = [];

  // Services where the data from the store is not filtered before loading into memory - e.g. drugs
  private unfilteredServices: ISyncService[] = [];

  private onDemandServices: ISyncService[] = [];

  // Services that are polled for changes
  private syncedServices: ISyncService[] = [];

  private allServices: ISyncService[] = [];
  constructor(
    private epochStore: MemoryCache<string>,
    private storeConfiguration: MemoryCache<StoreConfigurationDto>,
    private facilitiesService: SyncFacilities,
    private facilityGroupsService: SyncFacilityGroups,
    private corporatesService: SyncCorporates,
    private roundsService: SyncRounds,
    private drugsService: SyncDrugs,
    private drugFormsService: SyncDrugForms,
    private drugCategoriesService: SyncDrugCategories,
    private testResultTypesService: SyncTestResultTypes,
    private secondCheckSettingsService: SyncSecondCheckSettings,
    private pinUsersService: SyncPinUsers,
    private nimAvailableDrugsService: SyncNimAvailableDrugs,
    private facilityGroupConfigurationsService: SyncFacilityGroupConfigurations,
    private patientsService: SyncPatients,
    private packedPatientDaysService: SyncPackedPatientDays,
    private packedPatientPrnMedicationService: SyncPackedPatientPrnMedication,
    private ordersService: SyncOrders,
    private patchObservationsService: SyncPatchObservations,
    private usersService: SyncUsers,
    private testResultsService: SyncTestResults,
    private syringeDriverActivitiesService: SyncSyringeDriverActivities,
    private disableSyncing?: boolean
  ) {

    // The initialServices are also onDemand services.
    this.initialServices = [
      this.facilitiesService,
      this.facilityGroupsService,
      this.corporatesService,
      this.testResultTypesService,
    ];
    this.filteredServices = [
      this.patientsService,
      this.packedPatientDaysService,
      this.packedPatientPrnMedicationService,
      this.roundsService,
      this.ordersService,
      this.patchObservationsService,
      this.testResultsService,
      this.syringeDriverActivitiesService
    ];

    this.singleFacilityGroupServices = [
      this.usersService,
      this.pinUsersService,
      this.secondCheckSettingsService,
      this.nimAvailableDrugsService,
      this.facilityGroupConfigurationsService
    ];

    this.unfilteredServices = [
      this.drugsService,
      this.drugFormsService,
      this.drugCategoriesService,
    ];

    this.syncedServices = [
      ...this.unfilteredServices,
      ...this.filteredServices
    ];
    this.allServices = [
        ...this.syncedServices,
        ...this.onDemandServices,
        ...this.singleFacilityGroupServices,
    ];
  }

  async performInitialSync() {
    if (this.disableSyncing) {
      logger.info('Syncing is disabled');
      return;
    }

    // Go through each service and sync them all synchronously as there may be data dependencies.
    // No locks should be required at this point as UI cannot see or change any data.
    const stopwatch = beginStopwatch();
    logger.info(`Initial sync started...`);
    await Promise.all(this.initialServices.map((service) => this.sync(service, false)));
    logger.info(`Initial sync completed: ${stopwatch.done()}`);
  }

  async loadSingleFacilityGroupData(facilityGroupId: string) {
    const requiredEncryptionVersion: number = (await this.facilityGroupConfigurationsService?.getEncryptionVersion(facilityGroupId)) ?? 1;
    // Re-load all the per facility group stores.
    for(const service of this.singleFacilityGroupServices) {
      logger.info(`Clearing data for ${service.name}`);
      await service.clear();
      service.setEncryptionVersion(requiredEncryptionVersion);
    }
    for(const service of this.singleFacilityGroupServices) {
      logger.info(`Downloading data for ${service.name}`);
      await service.syncDown(facilityGroupId);
    }
  }

    async loadFacilityGroupData(facilityGroupId: string) {
    // Get the required version.
    const requiredEncryptionVersion: number = (await this.facilityGroupConfigurationsService?.getEncryptionVersion(facilityGroupId)) ?? 1;
    const currentEncryptionVersion: number = (await this.storeConfiguration.get(facilityGroupId))?.version ?? 1;

    // Load all the indexed db stores that are filtered on facility group id.
    logger.info("Starting cache load");
    for(const mrsService of [...this.filteredServices, ...this.singleFacilityGroupServices]) {
      mrsService.setEncryptionVersion(requiredEncryptionVersion);
      logger.info(`Loading cache for ${mrsService.name}`);
      await mrsService.load(facilityGroupId);

      if (requiredEncryptionVersion != currentEncryptionVersion) {
        logger.info("Migrating database store version from " + currentEncryptionVersion + " to " + requiredEncryptionVersion);
        await mrsService.rewrite();
        await this.storeConfiguration.set(facilityGroupId, {
          facilityGroupId: facilityGroupId,
          version: requiredEncryptionVersion
        });
      }
    }
    logger.info("Cache load completed");
  }
  async performSingleFacilityGroupLoad(facilityGroupId: string): Promise<boolean> {
    if (this.disableSyncing) {
      logger.info('Syncing is disabled');
      return false;
    }
    if (!isSingleFacilityLoadInProgress) {
      try {
        setSingleFacilityLoadInProgress(true);
        await this.loadSingleFacilityGroupData(facilityGroupId);
      } finally {
        setSingleFacilityLoadInProgress(false);
      }
      return true;
    }
    return false;

  }
  async performFacilityGroupLoad(facilityGroupId: string): Promise<boolean> {
    if (this.disableSyncing) {
      logger.info('Syncing is disabled');
      return false;
    }
    if (!isCacheLoadInProgress) {
      try {
        setCacheLoadInProgress(true);
        await this.loadFacilityGroupData(facilityGroupId);
     } finally {
        setCacheLoadInProgress(false);
      }
      return true;
    }
    return false;
  }

  async performInitialFacilitySync(facilityGroupId: string, canUserAccessMedication: boolean): Promise<boolean> {
    if (this.disableSyncing) {
      logger.info('Syncing is disabled');
      return false;
    }
    let loaded = false;
    if (!isCacheLoadInProgress) {
      try {
        setCacheLoadInProgress(true);

        await this.loadFacilityGroupData(facilityGroupId);
        loaded = true;
        logger.info(`Initial facility group sync started...`);

        for (const service of [...this.syncedServices]) {
          await this.sync(service, canUserAccessMedication, facilityGroupId);
        }

        logger.info(`Initial facility group sync completed`);
      } finally {
        setCacheLoadInProgress(false);
      }
    }
    return loaded;
  }

  async clearStores(resetAllStores: boolean) {
    let servicesToClear: ISyncService[] = [
        ...this.initialServices,
        ...this.onDemandServices
    ];

    if (resetAllStores) {
      servicesToClear = [...this.allServices];
    }
    for (const service of servicesToClear) {
      await service.clear();
    }
    if (resetAllStores) {
      await this.epochStore.clear();
    }
  }


  async hasQueuedData() {
    for (const service of [...this.allServices]) {
      if (await service.hasQueuedData()) {
        logger.info(`Service - ${service.name} has queued data`);
        setSyncRequired(true);
        return true;
      }
    }
    return false;
  }

  async start(canUserAccessMedication: boolean, facilityGroupId?: string): Promise<void> {
    if (this.disableSyncing) {
      logger.info('Syncing is disabled');
      return;
    }

    if (this.interval) {
      logger.warn('Sync already started');
      return;
    }
    if (!facilityGroupId) {
      return;
    }

    this.interval = setInterval(
        withLock(async () => {
          const now = new Date();
          if (now.getTime() - lastSyncedAt.getTime() > (60 * 1000)) {
            // Sync if we haven't synced for at least 1 minute.
            setSyncRequired(true);
          }
          if (isSyncRequired && !isSyncInProgress && !isCacheLoadInProgress) {
            try {
              setSyncInProgress(true);
              setSyncRequired(false);
              logger.info("Starting sync");
              for (const service of this.syncedServices) {
                await this.sync(service, canUserAccessMedication, facilityGroupId);
              }
              logger.info("Sync completed");

              if (!hasBeenArchived) {
                // Archive any stale records.
                await this.archive();
                setHasBeenArchived(true);
              }
            } finally {
              setSyncInProgress(false);
            }
          }
        }), 1000
    );
  }
  async archive() {
    // Remove all the old data.  Start at the top and work our way down.
    // Do all in 'syncedServices'.
    logger.info("Starting archival of old records");

    logger.info("Archiving Patients");
    const patientIds = await this.patientsService.archive();

    logger.info("Archiving Packed Patient Days");
    await this.packedPatientDaysService.archive(patientIds);
    logger.info("Archiving Packed Patient PRN medication");
    await this.packedPatientPrnMedicationService.archive(patientIds);

    logger.info("Archiving Orders");
    await this.ordersService.archive(patientIds);
    logger.info("Archiving Rounds");
    await this.roundsService.archive();
    logger.info("Archiving Test Results");
    await this.testResultsService.archive(patientIds);
    logger.info("Archiving Patch Observations");
    await this.patchObservationsService.archive(patientIds);
    logger.info("Archiving Syringe Driver Activities");
    await this.syringeDriverActivitiesService.archive(patientIds);


    logger.info("Archiving Drugs");
    await this.drugsService.archive();
    logger.info("Archiving Drug Forms");
    await this.drugFormsService.archive();


    logger.info("Archival completed");
  }

  stop() {
    if (!this.interval) {
      // logger.warn('Sync already stopped');
      return;
    }
    clearInterval(this.interval);
    this.interval = undefined;
  }

  private async sync(service: ISyncService, canUserAccessMedication: boolean, facilityGroupId?: string) {
    const stopwatch = beginStopwatch();

    //Should allow anyone to Sync Up
    logger.info(`Sync up ${service.name}`);
    await service.syncUp();

    if (!(service.isAllowed(canUserAccessMedication))) {
      logger.info(`Ignoring ${service.name}`);
      // Don't have permission to sync this Down, so don't.
      return;
    }
    logger.info(`Sync down ${service.name}`);
    await service.syncDown(facilityGroupId);
    logger.info(`Sync ${service.name} complete: ${stopwatch.done()}`);
  }
}

function withLock(callback: () => Promise<void>) {
  return async () => {
    await lock.run(async () => {
      await callback();
    });
  };
}
