import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Grid } from '../../kit/Grid';
import { IconBack } from '../../kit/Icons/Back';
import { Layout } from '../../kit/Layout';
import { SupportInformation } from './SupportInformation';

export function SupportInformationPage() {
  const history = useHistory();
  return (
    <SupportContainer cols={2} gap={2} colsTemplate={'auto 1fr'}>
      <BackButtonContainer onClick={() => history.goBack()}>
        <IconBack />
      </BackButtonContainer>
      <InfoContainer>
        <Header>Please contact Customer Support for assistance</Header>
        <SupportInformation />
      </InfoContainer>
    </SupportContainer>
  );
}

const SupportContainer = styled(Grid)`
  background-image: linear-gradient(165deg, #ad51f7 0%, #c24ddd 45%, #fa4197 80%);
  width: 100%;
  height: 100%;
  padding: 2.5rem;
`;

const BackButtonContainer = styled(Layout)`
  cursor: pointer;
`;

const InfoContainer = styled(Grid)`
  grid-template-rows: 1fr 3fr;
`;

const Header = styled.h1`
  color: ${(p) => p.theme.backgrounds.default.fg};
`;
