import { Paper } from '../../../kit/Paper';
import { Text } from '../../../kit/Text';
import { DateUtils } from '../../../core/utils/dateUtils';
import React from 'react';
import styled from 'styled-components';
import { Image } from '../../../kit/Image';
import { RoundScheduleItem } from "../../../syncstream/utils/RoundUtils";
import {HSDrug, HSFacility, HSFacilityGroup, HSPatient} from "server-openapi";
import {DrugLabel} from "../../ResidentDetails/components/medicationInformation/DrugDetails";
import {ResidentThumbnail} from "../../Rounds/CreateRoundPage/components/ResidentRow";
import {groupBy} from "lodash-es";
import {Layout} from "../../../kit/Layout";
import {useHistory} from "react-router";

export interface IMissedDosesCardParams {
  patientName: string;
  patientId?: number;
  scheduleItems: RoundScheduleItem[];
  facilityGroup: HSFacilityGroup;
  drugStore: ReadonlyMap<string, HSDrug>;
  facilityStore: ReadonlyMap<string, HSFacility>;
}

function getDoseTime(scheduleItem: RoundScheduleItem) {
  if (!!scheduleItem.scheduledActivity) {
    return DateUtils.dateTo24HourTimeString(scheduleItem.scheduledActivity.time);
  }
  return DateUtils.dateTo24HourTimeString(DateUtils.toDate(scheduleItem.packedMedication.doseTimestamp ?? ''));
}
export function MissedDosesCard(props: IMissedDosesCardParams) {
  const patient = props.scheduleItems[0].patient;
  const doseTimeScheduleItemMap = groupBy(props.scheduleItems, (si) => getDoseTime(si));
  const sortedDoseTimes = Object.keys(doseTimeScheduleItemMap).sort();
  const history = useHistory();
    function navigate(patient: HSPatient) {
    history.push(`/resident-details/${patient.hsId}/`)
  }


  const facility = props.facilityStore.get(patient.facility?.toString() ?? '');
  return (
    <Paper>
      <InformationBox horizontal gap={1}>
        <PatientImageColumn style={{ cursor: 'pointer' }} onClick={() => navigate(patient)}>
            {patient.imageUrl && <ResidentThumbnail src={patient.imageUrl!} facilityGroupId={props.facilityGroup.hsId ?? 0} /> }
        </PatientImageColumn>
        <PatientDetailColumn>
            <Text weight={'bold'}>{props.patientName} </Text>
            <Text>
              {facility?.name} Rm {patient.roomNumber}
            </Text>
        </PatientDetailColumn>
        <MissedDoseColumn>
        {
            sortedDoseTimes.map((doseTime, index) => {
                return (<MissedDoseItemDetail doseTime={doseTime} scheduleItems={doseTimeScheduleItemMap[doseTime]} patient={patient} facility={facility!} drugStore={props.drugStore} key={ index}/>);
            })
        }
        </MissedDoseColumn>

      </InformationBox>
    </Paper>
  );

}

export interface IMissedDoseItemDetailParams {
  doseTime: string;
  scheduleItems: RoundScheduleItem[];
  patient: HSPatient;
  facility: HSFacility;
  drugStore: ReadonlyMap<string, HSDrug>;
}
export function MissedDoseItemDetail(props: IMissedDoseItemDetailParams) {
    const sortedScheduleItems = props.scheduleItems.sort((a, b) => {
        const drugA = props.drugStore.get(a.packedMedication?.drugHsId?.toString() ?? '')?.name ?? '';
        const drugB = props.drugStore.get(b.packedMedication?.drugHsId?.toString() ?? '')?.name ?? '';
        return drugA.localeCompare(drugB);
    });
  return (
      <MissedDoseDetailContainer >
          <DoseTimestampColumn className={'title'}>{props.doseTime} </DoseTimestampColumn>
          {sortedScheduleItems.map((x, index) => {
              return (<MissedDoseDrugDetail doseTime={props.doseTime} drugStore={props.drugStore} patient={props.patient} facility={props.facility} scheduleItem={x} key={index}/>);
          })}
      </MissedDoseDetailContainer>
  )
}
export interface IMissedDoseDrugDetailParams {
    doseTime: string;
    scheduleItem: RoundScheduleItem;
    patient: HSPatient;
    facility: HSFacility;
    drugStore: ReadonlyMap<string, HSDrug>;
}

export function MissedDoseDrugDetail(props: IMissedDoseDrugDetailParams) {
    const drug = props.drugStore.get(props.scheduleItem.packedMedication.drugHsId?.toString() ?? '');
    const description = props.scheduleItem.scheduledActivity?.kind ?? '';
    return (
        <Container gap={0}>
            <InformationBox horizontal gap={2}>
                <Layout gap={1} style={{ width: '100%' }}>
                    <DrugDescriptionColumn>
                        <DrugImage src={drug?.imageUri ?? ''} facilityGroupId={props.facility.facilityGroupId ?? 0} />
                    </DrugDescriptionColumn>
                    <DrugDescriptionColumn>{description}</DrugDescriptionColumn>
                    { drug && <DrugLabel drug={drug} isCeased={false} isPsychotropicConsent={false} />}
                </Layout>
            </InformationBox>
        </Container>
    )
}

// const InformationBox = styled.div`
//   display: grid;
//   vertical-align: top;
// `;
const InformationBox = styled(Layout)`
  display: flex;
  align-items: center;
`;
const Container = styled(Layout)`
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const PatientImageColumn = styled.div`
  padding-right: 1.25rem;
`;

const PatientDetailColumn = styled.div`
  padding-right: 1.25rem;
  flex-basis: 35%;
`;

const MissedDoseColumn = styled.div`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  color: black;
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const DoseTimestampColumn = styled.div`
  padding-right: 1.25rem;
  flex-basis: 5rem;
  font-weight: bold;
  font-size: 16px;
`;
const DrugDescriptionColumn = styled.div`
  padding-right: 1.25rem;
  display: inline-block;
  vertical-align: top;
`;
const MissedDoseDetailContainer = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 0.5em;
  margin-left: 1em;
  div {
     display: inline-block;
     vertical-align: top; 
  }
`;
const DrugImage = styled(Image)`
  height: 35px;
`;
