import React, { useEffect, useState } from 'react';
import { appconfig } from '../../appconfig';
import { createServiceProvider } from '../../context/AppServiceProvider';
import { Logger } from '../logger/logger';
import {useAppState} from "../../context/AppStateProvider";

// Ideally we would have 3 statuses 'unreachable' (no resp) | 'unhealthy' | 'healthy'
interface HealthCheckContext {
  isHealthy: boolean;
}

const logger = new Logger('HealthCheckProvider');
const [ServiceProvider, useHook] = createServiceProvider<HealthCheckContext>();

export const useHealthCheck = useHook;

interface Props {
  children?: React.ReactNode;
}

export const HealthCheckProvider = (props: Props) => {
  const [isHealthy, setIsHealthy] = useState<boolean>(true);
  const [isAppSessionHealthy, setIsAppSessionHealthy] = useState<boolean>(true);
  const appState = useAppState();

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const res = await fetch(appconfig.API_URL + '/health');
        setIsHealthy(res.ok);
      } catch {
        setIsHealthy(false);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    logger.info('Server health check: ', isHealthy ? 'Online' : 'Offline');
  }, [isHealthy]);

  return <ServiceProvider value={{ isHealthy: isHealthy }}>{props.children}</ServiceProvider>;
};
