import { CSSProperties } from 'styled-components';

enum PatchArea {
  FrontRightChest = 'Front Right Chest',
  FrontLeftChest = 'Front Left Chest',
  BackLeftShoulder = 'Back Left Shoulder',
  BackRightShoulder = 'Back Right Shoulder',
  RightUpperArm = 'Right Upper Arm',
  RightTorso = 'Right Torso',
  LeftUpperArm = 'Left Upper Arm',
  LeftTorso = 'Left Torso',
}

export interface PatchLocationDTO {
  LocationNumber: number;
  PatchArea: PatchArea;
  PositionInfo: CSSProperties; // position the marker using top % absolute wrt. parent container and translate horizontally based on the middle of the (mirror) avatars image
}

export const PatchLocations: PatchLocationDTO[] = [
  {
    LocationNumber: 1,
    PatchArea: PatchArea.FrontRightChest,
    PositionInfo: { top: '24%', transform: 'translate(-170px)' },
  },
  {
    LocationNumber: 2,
    PatchArea: PatchArea.FrontRightChest,
    PositionInfo: { top: '24%', transform: 'translate(-138px)' },
  },
  {
    LocationNumber: 3,
    PatchArea: PatchArea.FrontRightChest,
    PositionInfo: { top: '30%', transform: 'translate(-170px)' },
  },
  {
    LocationNumber: 4,
    PatchArea: PatchArea.FrontRightChest,
    PositionInfo: { top: '30%', transform: 'translate(-138px)' },
  },
  {
    LocationNumber: 5,
    PatchArea: PatchArea.FrontLeftChest,
    PositionInfo: { top: '24%', transform: 'translate(-103px)' },
  },
  {
    LocationNumber: 6,
    PatchArea: PatchArea.FrontLeftChest,
    PositionInfo: { top: '24%', transform: 'translate(-71px)' },
  },
  {
    LocationNumber: 7,
    PatchArea: PatchArea.FrontLeftChest,
    PositionInfo: { top: '30%', transform: 'translate(-103px)' },
  },
  {
    LocationNumber: 8,
    PatchArea: PatchArea.FrontLeftChest,
    PositionInfo: { top: '30%', transform: 'translate(-71px)' },
  },
  {
    LocationNumber: 9,
    PatchArea: PatchArea.BackLeftShoulder,
    PositionInfo: { top: '24%', transform: 'translate(41px)' },
  },
  {
    LocationNumber: 10,
    PatchArea: PatchArea.BackLeftShoulder,
    PositionInfo: { top: '24%', transform: 'translate(73px)' },
  },
  {
    LocationNumber: 11,
    PatchArea: PatchArea.BackLeftShoulder,
    PositionInfo: { top: '30%', transform: 'translate(41px)' },
  },
  {
    LocationNumber: 12,
    PatchArea: PatchArea.BackLeftShoulder,
    PositionInfo: { top: '30%', transform: 'translate(73px)' },
  },
  {
    LocationNumber: 13,
    PatchArea: PatchArea.BackRightShoulder,
    PositionInfo: { top: '24%', transform: 'translate(108px)' },
  },
  {
    LocationNumber: 14,
    PatchArea: PatchArea.BackRightShoulder,
    PositionInfo: { top: '24%', transform: 'translate(140px)' },
  },
  {
    LocationNumber: 15,
    PatchArea: PatchArea.BackRightShoulder,
    PositionInfo: { top: '30%', transform: 'translate(108px)' },
  },
  {
    LocationNumber: 16,
    PatchArea: PatchArea.BackRightShoulder,
    PositionInfo: { top: '30%', transform: 'translate(140px)' },
  },
  {
    LocationNumber: 17,
    PatchArea: PatchArea.RightUpperArm,
    PositionInfo: { top: '75%', transform: 'translate(-135px)' },
  },
  {
    LocationNumber: 18,
    PatchArea: PatchArea.RightUpperArm,
    PositionInfo: { top: '81%', transform: 'translate(-135px)' },
  },
  {
    LocationNumber: 19,
    PatchArea: PatchArea.RightTorso,
    PositionInfo: { top: '83%', transform: 'translate(-92px)' },
  },
  {
    LocationNumber: 20,
    PatchArea: PatchArea.RightTorso,
    PositionInfo: { top: '89%', transform: 'translate(-92px)' },
  },
  {
    LocationNumber: 21,
    PatchArea: PatchArea.LeftUpperArm,
    PositionInfo: { top: '75%', transform: 'translate(99px)' },
  },
  {
    LocationNumber: 22,
    PatchArea: PatchArea.LeftUpperArm,
    PositionInfo: { top: '81%', transform: 'translate(99px)' },
  },
  {
    LocationNumber: 23,
    PatchArea: PatchArea.LeftTorso,
    PositionInfo: { top: '83%', transform: 'translate(55px)' },
  },
  {
    LocationNumber: 24,
    PatchArea: PatchArea.LeftTorso,
    PositionInfo: { top: '89%', transform: 'translate(55px)' },
  },
];
