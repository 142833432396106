/* eslint-disable max-lines-per-function */
import React from 'react';
import { useForm } from 'react-hook-form';
import { HSConsiderations, HSFacilityGroup, HSPatient, UpdatePatientReq } from 'server-openapi';
import styled from 'styled-components';
import { Button } from '../../../../kit/Button';
import { Dialog } from '../../../../kit/Dialog';
import { Form } from '../../../../kit/Forms/Form';
import { Checkbox } from '../../../../kit/Forms/CheckBoxFormInput';
import { RadioGroup } from '../../../../kit/Forms/RadioGroup';
import { Select } from '../../../../kit/Forms/Select';
import { TextArea } from '../../../../kit/Forms/TextArea';
import { TextInput } from '../../../../kit/Forms/TextInput';
import { Grid } from '../../../../kit/Grid';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { SyncPatients } from '../../../../syncstream/SyncPatients';
import { useCurrentUser } from '../../../../core/authn/UserProvider';
import { User } from 'oidc-client';
import _ from 'lodash';
import { ResidentDetailsUtils } from '../../../../syncstream/utils/ResidentDetailsUtils';
import { MemoryCache } from '../../../../core/storage/MemoryCache';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";
interface IProps {
  patient: HSPatient;
  facilityGroup: HSFacilityGroup;
  isOpen: boolean;
  setClosed: () => void;
}
interface FormFields {
  suspended?: boolean;
  suspendedComment?: string;
  specialInstructions?: string;
  selfAdministered?: boolean;
  considerations?: HSConsiderations;
  swallowDifficulty?: boolean;
}

interface IEditPermissions {
  canEditOffsiteStatus: boolean;
  canEditSpecialInstructions: boolean;
  canEditSpecialConsiderations: boolean;
  canMarkResidentFullySelfAdministered: boolean;
}

const siteOptions = [
  {
    label: 'On-site',
    value: false,
  },
  {
    label: 'Off-site',
    value: true,
  },
];

const offSiteReasons = [
  {
    label: 'Hospital Admission',
    value: 'Hospital Admission',
  },
  {
    label: 'Social Leave',
    value: 'Social Leave',
  },
];

async function updatePatient(
  data: FormFields,
  patient: HSPatient,
  user: User,
  patientService: {
    store: MemoryCache<HSPatient>;
    service: SyncPatients;
  },
  facilityGroupId: number,
  residentDetailUtils: ResidentDetailsUtils,
  dirtyFields: string[],
) {
  const updatedPatient: HSPatient = {
    ...patient,
    suspended: data.suspended,
    suspendedComment: data.suspended ? data.suspendedComment : undefined,
    specialInstructions: data.specialInstructions,
    lastUpdatedBySubjectId: user.profile.sub,
    considerations: data.considerations,
    swallowDifficulty: data.swallowDifficulty,
    selfAdministered: data.selfAdministered,
  };

  //create the update patient request object
  const updatePatientReq: UpdatePatientReq = {
    patient: updatedPatient,
    facilityGroupId: facilityGroupId,
  };

  // enqueue the updated patient
  await patientService.service.enqueue.updatePatientInfo({ type: 'update-patient-info', request: updatePatientReq });

  // create a separate progress note for every field which is edited
  for (const field of dirtyFields) {
    const logText = residentDetailUtils.getUpdateLogText(updatedPatient, field);
    await residentDetailUtils.addPatientProgressNote(
      patient,
      facilityGroupId,
      'Patient Profile Changes',
      logText,
      '',
      patientService.service,
      user,
    );
  }

  // run the local update function to update medications if the self administered status is updated
  if (dirtyFields.includes('selfAdministered') || dirtyFields.includes('swallowDifficulty')) {
    await residentDetailUtils.updateAllPatientMedicationSAStatus(patientService.store, updatedPatient.hsId!.toString());
  }
}

//to reduce cognitive complexity
function getDefaultFormFieldValues(patient: HSPatient): FormFields {
  return {
    suspended: patient.suspended ?? false,
    suspendedComment: patient.suspendedComment ?? '',
    specialInstructions: patient.specialInstructions ?? '',
    selfAdministered: patient.selfAdministered ?? false,
    considerations: patient.considerations ?? {},
    swallowDifficulty: patient.swallowDifficulty ?? false,
  };
}

export function UpdateResidentDialog(props: IProps) {
  return (
    <Dialog lazy secondary open={props.isOpen} onRequestClose={() => props.setClosed()} size={'lg'}>
      <ResidentDetailForm {...props} />
    </Dialog>
  );
}

function ResidentDetailForm(props: IProps) {
  const patientUtils = useApiUtils().patients;
  const facilityUtils = useApiUtils().facilities;
  const facilityGroupUtils = useApiUtils().facilityGroups;
  const residentDetailUtils = useApiUtils().residentDetails;
  const services = useSyncCenter();
  const facilityGroupId = patientUtils.findFacilityGroup(props.patient);
  // Get the wing label
  const wingLabel = facilityGroupUtils.getFacilityUILabel(facilityGroupId);
  const facility = facilityUtils.fetchFacilityById(props.patient.facility!);
  const user = useCurrentUser();
  const groupPermissions = useGroupPermissions();
  const editPermissions: IEditPermissions = {
    canEditOffsiteStatus: groupPermissions.canEditResidentOffsiteStatus ?? false,
    canEditSpecialInstructions: groupPermissions.canEditResidentSpecialInstruction ?? false,
    canEditSpecialConsiderations: groupPermissions.canEditResidentSpecialConsiderations ?? false,
    canMarkResidentFullySelfAdministered: groupPermissions.canMarkResidentAsFullySelfAdministered ?? false
  };

  const form = useForm<FormFields>({
    defaultValues: getDefaultFormFieldValues(props.patient),
  });

  const { dirtyFields } = form.formState;

  const watchSuspended = form.watch('suspended');

  function onSubmit(data: FormFields) {
    //TODO: Add front end permission check here for users who cannot update patients
    updatePatient(
      data,
      props.patient,
      user,
      services.patients,
      facilityGroupId,
      residentDetailUtils,
      Object.keys(dirtyFields),
    );
    props.setClosed();
    toasts.success('Patient details updated successfully');
  }

  function triggerSuspendedComment() {
    if (form.formState.isSubmitted) {
      form.trigger('suspendedComment');
    }
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Layout gap={1}>
        <Text weight={'bold'} size={'medium'}>
          {patientUtils.getDisplayPatientName(props.patient)}
        </Text>
        <RadioGroup
          onChange={triggerSuspendedComment}
          value={!!props.patient.suspended}
          cols={2}
          name={'suspended'}
          disabled={!editPermissions.canEditOffsiteStatus}
          options={siteOptions}
        />
        {watchSuspended && (
          <Layout gap={0.5}>
            <Text weight={'bold'}>Reason for Offsite</Text>
            <Select
              name={'suspendedComment'}
              options={offSiteReasons}
              fullWidth
              placeholder={'Select a reason'}
              required
              disabled={!editPermissions.canEditOffsiteStatus}
              rules={{
                validate: (suspendedComment: string) => {
                  if (watchSuspended && suspendedComment.length === 0) {
                    return 'Reason required if offsite';
                  }
                },
              }}
            />
          </Layout>
        )}

        <Layout gap={0.5}>
          <Text weight={'bold'}>Preferred Name</Text>
          <TextInput disabled name={'name'} value={props.patient.preferredName ?? ''} fullWidth />
        </Layout>
        <Grid colsTemplate={'1fr 0.5fr'} gap={2}>
          <Layout gap={0.5}>
            <Text weight={'bold'}>Doctor</Text>
            <TextInput name={'doctor'} value={props.patient.doctor?.name ?? ''} disabled fullWidth />
          </Layout>
          <Layout gap={0.5}>
            <Text weight={'bold'}>Doctor Phone Number</Text>
            <TextInput name={'doctorphonenumber'} value={props.patient.doctor?.phone ?? ''} disabled fullWidth />
          </Layout>
        </Grid>
        <Grid colsTemplate={'1fr 0.5fr'} gap={2}>
          <Layout style={{ width: '100%' }} gap={0.5}>
            <Text weight={'bold'}>{wingLabel}</Text>
            <TextInput disabled fullWidth name={'wing'} value={facility?.name ?? ''} />
          </Layout>
          <Layout style={{ width: '100%' }} gap={0.5}>
            <Text weight={'bold'}>Room</Text>
            <TextInput disabled fullWidth name={'room'} value={props.patient.roomNumber ?? ''} />
          </Layout>
        </Grid>
        <Layout gap={0.5}>
          <Text weight={'bold'}>Special Instructions</Text>
          <TextArea disabled={!editPermissions.canEditSpecialInstructions} name={'specialInstructions'} fullWidth />
        </Layout>
        <Layout gap={0.5}>
          <Text weight={'bold'}>Special Considerations</Text>
          <Grid cols={3} gap={1}>
            {Object.keys(props.patient.considerations!).map((key, index) => {
              return (
                <Layout key={index} align={'center'} horizontal gap={0.2}>
                  <Checkbox disabled={!editPermissions.canEditSpecialConsiderations} name={`considerations.${key}`} />
                  <Text>{_.startCase(key)}</Text>
                </Layout>
              );
            })}
            <Layout key={`swallowDifficulty`} align={'center'} horizontal gap={0.2}>
              <Checkbox disabled={!editPermissions.canEditSpecialConsiderations} name={`swallowDifficulty`} />
              <Text>Swallowing Difficulties</Text>
            </Layout>
          </Grid>
        </Layout>

        <Layout align={'center'} horizontal gap={0.2}>
          <Checkbox
            disabled={!editPermissions.canMarkResidentFullySelfAdministered}
            roundInput
            name={'selfAdministered'}
          />
          <Text weight={'bold'}>Always Self-administering</Text>
        </Layout>
        <ButtonContainer>
          {Object.keys(dirtyFields).length > 0 && (
            <Layout style={{ width: '6em' }}>
              <Button type="submit" fullWidth>
                Save
              </Button>
            </Layout>
          )}
          <Layout style={{ width: '6em' }}>
            <Button onClick={() => props.setClosed()} fullWidth>
              {Object.keys(dirtyFields).length > 0 ? 'Cancel' : 'Ok'}
            </Button>
          </Layout>
        </ButtonContainer>
      </Layout>
    </Form>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5em;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;
