import React, { useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { appconfig } from './appconfig';
import { useAppState } from './context/AppStateProvider';
import { AuthCallback } from './core/authn/AuthCallback';
import { SilentRenewCallback } from './core/authn/SilentRenewCallback';
import { logout } from './core/authn/Client';
import { useCurrentUser, UserProvider } from './core/authn/UserProvider';
import { HealthCheckProvider } from './core/healthcheck/HealthCheckProvider';
import { setSentryUser } from './core/logger/sentry';
import { AxiosEventEmitter } from './core/mrs/apis';
import { toasts } from './kit/Toasts/Toaster';
import { AcceptInvitationPage } from './pages/Admin/AcceptInvitationPage/AcceptInvitationPage';
import { Admin } from './pages/Admin/Admin';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { FacilityManagement } from './pages/FacilityManagement/FacilityManagement';
import { HelpPage } from './pages/Help/HelpPage';
import { ResidentDetails } from './pages/ResidentDetails/ResidentDetails';
import { Rounds } from './pages/Rounds/Rounds';
import { SupportInformationPage } from './pages/Support/SupportInformationPage';
import { SwitchFacilityPage } from './pages/SwitchFacilityPage/SwitchFacilityPage';
import { SyncCenterProvider } from './syncstream/SyncCenterProvider';
import { MaintenanceWarningDialog } from './pages/GlobalDialogs/MaintenanceWarningDialog';
import { OfflineWarning } from './pages/GlobalDialogs/OfflineWarning';
import { ForcedPinDialog } from './components/PinComponents/ForcedPinDialog';
import { LockScreenDialog } from './components/PinComponents/LockScreenDialog';
import { UserAccessCheck } from './UserAccessCheck';
import { RoundScheduleProvider } from './pages/Rounds/services/RoundScheduleProvider';
import { MemoryCacheProvider } from "./core/storage/hooks/MemoryCacheProvider";
import { GetScheduleProvider } from "./syncstream/utils/hooks/GetSchedule";
import {DashboardStateProvider} from "./pages/Dashboard/DashboardContext";
import {RoundFilterStateProvider} from "./pages/Rounds/services/RoundFilterContext";
import {PermissionsProvider} from "./core/authz/PermissionsProvider";
import {IndexedDbHealthProvider} from "./core/healthcheck/IndexedDbHealthProvider";

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AxiosErrorListener
        onUnauthorized={() => {
            toasts.error('An authorization error has occurred.');
        }}
      />
      <Switch>
        {/* need to keep the auth callback path out of the sam provider */}
        {/* because the sam provider is very slow to load */}
        <Route exact path="/auth/callback">
          <AuthCallback />
        </Route>
        <Route exact path="/auth/renew" component={SilentRenewCallback} />
        <Route>
          <UserProvider
            onUserLoaded={(user) => {
              setSentryUser(user.profile.sub, user.profile.name, user.profile.email?.toLowerCase());
            }}
            onUserUnloaded={() => setSentryUser()}
          >
            <Routes />
          </UserProvider>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

const Routes = () => {
  const appState = useAppState();
  const user = useCurrentUser();
  //console.count('Routes Start');
  return (
    <Switch>
      <Route exact path="/accept-invitation" component={AcceptInvitationPage} />
      <Route>
        <HealthCheckProvider>
            <PermissionsProvider>
            <UserAccessCheck />
            <SyncCenterProvider
              onInitialSyncComplete={() =>
                appState.set({
                  isInitialSyncComplete: true,
                  lastSyncedUserSub: user.profile.sub,
                  selectedFacilityGroupId: undefined,
                  isFacilityGroupInitialSyncComplete: undefined,
                  isFacilityGroupLoaded: undefined,
                })
              }
              resetStores={false}
              enableInitialSync={!appState.state.isInitialSyncComplete}
            >

                <IndexedDbHealthProvider >
                <MemoryCacheProvider>
                <GetScheduleProvider>
                <IdleTimer
                  timeout={1000 * 60 * 60}
                  onIdle={() => {
                    if (appconfig.ENVIRONMENT !== 'development') {
                      toasts.warning('You have been inactive for 1 hour and have been logged out.');
                      logout();
                    }
                  }}
                />
                <MaintenanceWarningDialog />
                <OfflineWarning />
                <LockScreenDialog />
                <ForcedPinDialog />
                <RoundScheduleProvider>
                  <DashboardStateProvider>
                  <RoundFilterStateProvider>
                    <Switch>
                      <Route path="/facility-group/:facilityGroupId/rounds" component={Rounds} />
                      <Route path="/facility-group/:facilityGroupId/management" component={FacilityManagement} />
                      <Route path="/facility-group/switch" component={SwitchFacilityPage} />
                      <Route path="/resident-details" component={ResidentDetails} />
                      <Route path="/admin" component={Admin} />
                      <Route exact path="/support" component={SupportInformationPage} />
                      <Route path="/facility-group/:facilityGroupId/help" component={HelpPage} />
                      <Route path="/" component={Dashboard} />
                    </Switch>
                    </RoundFilterStateProvider>
                  </DashboardStateProvider>
                </RoundScheduleProvider>
                </GetScheduleProvider>
              </MemoryCacheProvider>
              </IndexedDbHealthProvider>
            </SyncCenterProvider>
            </PermissionsProvider>
        </HealthCheckProvider>
      </Route>
    </Switch>
  );
};

function AxiosErrorListener(props: { onUnauthorized: () => void }) {
  useEffect(() => {
    const subscription = AxiosEventEmitter.on('error', (e: Error) => {
      if (e.message.includes('403')) {
        props.onUnauthorized();
      }
    });
    return () => {
      subscription.removeAllListeners('error');
    };
  }, []);

  return null;
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
