import React, { useEffect, useState } from 'react';
import { createServiceProvider } from '../../context/AppServiceProvider';
import { Logger } from '../logger/logger';
import { logout } from '../authn/Client';
import {indexedDbException, setIndexedDbException, useAppState} from "../../context/AppStateProvider";

// Ideally we would have 3 statuses 'unreachable' (no resp) | 'unhealthy' | 'healthy'
interface IndexedDbHealthContext {
  isHealthy: boolean;
}

const logger = new Logger('IndexedDbHealthCheck');
const [ServiceProvider, useHook] = createServiceProvider<IndexedDbHealthContext>();

export const useIndexedDbHealth = useHook;

interface Props {
  children?: React.ReactNode;
}

export const IndexedDbHealthProvider = (props: Props) => {
  const [isHealthy, setIsHealthy] = useState<boolean>(true);
  const appState = useAppState();

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        let data = JSON.parse(localStorage.getItem("APP_STATE") ?? '');
        if (indexedDbException) {
          setIndexedDbException(false);
          setIsHealthy(false);
        }
      } catch {
        setIsHealthy(false);
      }}, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!isHealthy) {
      logout('IndexedDBError');
    }
  }, [isHealthy]);

  return <ServiceProvider value={{ isHealthy: isHealthy }}>{props.children}</ServiceProvider>;
};
