import styled, { css } from 'styled-components';
import { Button } from '../../../../kit/Button';
import { colors } from '../../../../kit/Theme/Theme';

export const PatchBodyVisual = styled.div`
  background-image: url(${require('./all_torsos.png')});
  background-repeat: no-repeat;
  background-size: auto 95%;
  background-position: center;
  background-color: ${(prop) => prop.theme.backgrounds.darker.bg};
  min-height: 600px;
  position: relative;
`;

export const PatchLocationButton = styled(Button)<{
  locationNumber: number;
  isSelected?: boolean;
  recentlyPatched?: boolean;
}>`
  position: absolute;
  border-radius: 50%;
  color: white;
  outline: none;
  width: 31px;
  height: 31px;
  left: 50%;

  ${(props) => {
    if ([1, 2, 3, 4, 9, 10, 11, 12, 17, 18, 19, 20].includes(props.locationNumber)) {
      return css`
        background-color: ${props.theme.button.primary.bg};
      `;
    }

    if ([5, 6, 7, 8, 13, 14, 15, 16, 21, 22, 23, 24].includes(props.locationNumber)) {
      return css`
        background-color: ${colors.bg_pink_500};
      `;
    }
  }}

  ${(props) =>
    props.recentlyPatched &&
    css`
      background-color: ${colors.bg_gray_400};
    `};

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${colors.bg_yellow_250};
    `};
`;
