import { Lock } from '../../kit/Lock';
import { IStorage } from '../storage/Contract';

interface Delivery<T> {
  value: T;
  complete(): Promise<void>;
  failed(): Promise<void>;
}

export class PersistentQueue<T> {
  static async open<T>(storage: IStorage<T[]>): Promise<PersistentQueue<T>> {
    const queue = (await storage.get('queue')) ?? [];
    return new PersistentQueue<T>(storage, queue);
  }

  private readonly lock = new Lock();

  constructor(private storage: IStorage<T[]>, private queue: T[]) {}

  private failedAttemptsForIterationLoop = 0;

  async length() {
    return await this.lock.run(async () => {
      return this.queue.length;
    });
  }

  async unshift(item: T) {
    await this.lock.run(async () => {
      this.queue = [item, ...this.queue];
      await this.storage.set('queue', this.queue); // this.queue is immutable for some reason
    });
  }

  async pop(): Promise<Delivery<T> | undefined> {
    const unlock = await this.lock.acquire();

    // Stop looping if all the items in the queue are failed items
    if (this.queue.length <= this.failedAttemptsForIterationLoop) {
      unlock();
      return undefined;
    }

    const item = this.queue[this.queue.length - 1];
    this.queue = this.queue.slice(0, this.queue.length - 1);
    if (item === undefined) {
      unlock();
      return undefined;
    }

    return {
      value: item,
      complete: async () => {
        this.queue = this.queue;
        await this.storage.set('queue', this.queue);
        unlock();
      },
      failed: async () => {
        // Currently the default behaviour is to pop this request back onto the queue to be retried.
        // The obvious problem here is that it will loop forever trying again. Long term, we need a
        // Dead Letter Queue or similar. For now, only retry it on the next iteration batch

        this.failedAttemptsForIterationLoop += 1;

        this.queue = [item, ...this.queue];
        await this.storage.set('queue', this.queue);
        unlock();
      },
    };
  }

  async *iterate(): AsyncGenerator<Delivery<T>> {
    this.failedAttemptsForIterationLoop = 0;
    let delivery: Delivery<T> | undefined;
    while ((delivery = await this.pop())) {
      // this loop blocks until the delivery is complete() or failed()
      // because the following iteration will block on pop()
      // due to the queue being locked on the previous delivery.
      yield delivery;
    }
  }

  async clear() {
    await this.lock.run(async () => {
      this.queue = [];
      await this.storage.set('queue', this.queue);
    });
  }
}
