import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import { NavigationBar } from '../../components/NavigationBar/NavigationBar';
import styled from 'styled-components';
import { Grid } from '../../kit/Grid';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useStore } from '../../core/storage/hooks/UseStore';
import itiriri from 'itiriri';
import {GlobalPermissionsRes, GroupPermissionsRes, HSFacilityGroup} from 'server-openapi';
import { Text } from '../../kit/Text';
import { LayoutContainer, ViewSize } from '../../kit/LayoutContainer';
import { LinkButton } from '../../kit/LinkButton';
import { useAppState } from '../../context/AppStateProvider';
import { Button } from '../../kit/Button';
import { logout } from '../../core/authn/Client';
import { Intent } from '../../kit/Theme/Theme';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import { LogoutWarningDialog } from '../../core/authn/LogoutWarningDialog';
import { useQueueDataChecker } from '../../syncstream/utils/hooks/useQueueDataChecker';
import {useResetDashboardContext} from "../Dashboard/DashboardContext";
import {useGlobalPermissions} from "../../core/authz/PermissionsProvider";
import {apis} from "../../core/mrs/apis";

const LeftNav = styled(Grid)`
  display: flex;
  align-items: center;
`;

interface FacilityGroupItem extends HSFacilityGroup {
  userCount: number;
}

function getFacilityGroupItems(): FacilityGroupItem[] {
  const services = useSyncCenter();
  const apiUtils = useApiUtils();
  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilitiesStore = useStore(services.facilities.store).store;

  return itiriri(facilityGroupsStore.values())
    .toArray()
    .map((fg) => {
      const facilityIds = itiriri(facilitiesStore.values())
        .filter((f) => f.facilityGroupId === fg.hsId)
        .map((f) => f.hsId)
        .toArray();

      return {
        ...fg,
        userCount: apiUtils.patients
          .getActivePatients()
          .filter((p) => facilityIds.includes(p.facility))
          .length(),
      };
    });
}

export function SwitchFacilityPage() {
    //console.time('SwitchFacilityPage');
  const appState = useAppState();
  useResetDashboardContext();

  const hasQueuedData = useQueueDataChecker();

  const [open, setOpen] = React.useState(false);

  const sortedFacilityGroupItems = getFacilityGroupItems().sort((a, b) => {
    return (
      a.name?.localeCompare(b.name ?? '', 'en', {
        numeric: true,
      }) ?? 0
    );
  });

  // todo: easier way to create action and resourse request parameters?
  const globalPermissions = useGlobalPermissions();
  const canOnboardFacilities = globalPermissions.canOnboardFacilities;

  const header = canOnboardFacilities ? 'MediSphere Facilities' : 'Choose facility';
    //console.timeEnd('SwitchFacilityPage');
  return (
    <StyledLayoutContainer size={ViewSize.Medium}>
      <NavigationBar
        inverted
        nodes={() => ([
          appState.state.selectedFacilityGroupId ?
            <Link to="/">
              <h1>
                <LeftNav gap={1} cols={2}>
                  <IoArrowBackCircle /> {header}
                </LeftNav>
              </h1>
            </Link> :  <h1>{header}</h1>,
          <>
            <SettingsButton to="/admin/global-settings" intent={Intent.Secondary}>
              SETTINGS
            </SettingsButton>
            {canOnboardFacilities && (
              <SettingsButton to="/admin/onboarding/facility" intent={Intent.Secondary}>
                ADD FACILITY
              </SettingsButton>
            )}
            <LogoutButton
              onClick={async () => {
                if (hasQueuedData) {
                  setOpen(true);
                } else {
                  await logout();
                }
              }}
            >
              Log out
            </LogoutButton>
            <LogoutWarningDialog isOpen={open} setClosed={() => setOpen(false)} />
          </>
        ])}
      />

      {sortedFacilityGroupItems.map((facilityGroup, index) => (
        <FacilityGroupItem key={index} facilityGroup={facilityGroup} globalPermissions={globalPermissions}/>
      ))}
    </StyledLayoutContainer>
  );
}

interface IProps {
  facilityGroup: FacilityGroupItem;
  globalPermissions: GlobalPermissionsRes;
}

function FacilityGroupItem(props: IProps) {
    const [buttonText, setButtonText] = useState<string>('');
    const [canListPatients, setCanListPatients] = useState<boolean>(false);
    useEffect(() => {
       if (props.globalPermissions.canOnboardFacilities) {
           // If we are a sys admin, we can see everything.
           setCanListPatients(true);
           return;
       }
       // Check for access.
        apis.permissions.permissionsCanAccess(props.facilityGroup.hsId).then(result => {
           setCanListPatients(result.data);
        });
    });

  return (
    <FacilityGroupContainer>
      <Text weight={'bold'}>{props.facilityGroup.name?.toUpperCase()}</Text>
        <div onClick={() => setButtonText(`Loading...`)}>

                <ViewButton title={props.facilityGroup.name?.toUpperCase()} disabled={!canListPatients}
                            to={`${props.facilityGroup.hsId}`} onClick={() => setButtonText(`Loading...`)}>
                    {buttonText}
                </ViewButton>


            {/*
                <a href={`${props.facilityGroup.hsId}`} title={props.facilityGroup.name?.toUpperCase()}
                   onClick={() => setButtonText(`Loading...`)}>
                    <ViewFacilityButton disabled={!canListPatients}>
                        {buttonText}
                    </ViewFacilityButton>
                </a>
                */}
        </div>
    </FacilityGroupContainer>
);
}

const StyledLayoutContainer = styled(LayoutContainer)`
margin-top: 1rem;
`;

const FacilityGroupContainer = styled.div`
  color: ${(props) => props.theme.backgrounds.default.fg};
  background: ${(props) => props.theme.backgrounds.default.bg};
  margin: 10px 32px;
  padding: 32px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ViewFacilityButton = styled(Button)`
  width: 147px;
  font-size: 15px;
`;


const ViewButton = styled(LinkButton)`
  width: 147px;
  font-size: 15px;
  &:after{
        content: 'View';
        display: block;
    }
  &:active:after{
        content: 'Loading...';
        display: block;
    }
`;

const SettingsButton = styled(LinkButton)`
  margin-right: 20px;
  width: 147px;
  font-size: 15px;
`;

/*
 * The margin-right: -1rem is a hacky way of overriding grid-gap: 1rem on the outer grid container
 */
const LogoutButton = styled(Button)`
  padding: 0.5em 1em;
  width: 129px;
  border: solid 2px ${(props) => props.theme.button.secondary.bg};
  cursor: pointer;
  background: ${(props) => props.theme.body.background};
  font-weight: bold;
  font-size: 15px;
  color: ${(props) => props.theme.backgrounds.default.fg};
  margin-right: -1rem;
`;
