import styled from 'styled-components';

export const Badge = styled.div`
  color: #323232;
  background-color: #fff;
  border-radius: 2000em;
  position: relative;
  top: -0.5em;
  width: 1.2em;
  height: 1.2em;
  margin: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
`;
