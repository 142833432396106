import uniqueId from 'lodash/uniqueId';
import React from 'react';
import styled from 'styled-components';
import { FormInput, formInput } from './FormInput';
import { StyledTextInput } from './TextInput';
import { IoSearch } from 'react-icons/io5';

interface Props extends FormInput<string> {
  name: string;
  fullWidth?: boolean;
  suggestions?: string[];
  onSearch?: () => void;
}

const Container = styled.div<{ fullWidth?: boolean }>`
  border: solid;
  border-radius: ${(props) => props.theme.forms.input.border.radius};
  border-width: ${(props) => props.theme.forms.input.border.width};
  border-color: ${(props) => props.theme.forms.input.border.color};
  background-color: ${(props) => props.theme.forms.input.bg};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

const SearchTextInput = styled(StyledTextInput)`
  padding: ${(props) => props.theme.forms.input.padding};
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 40px;
  align-items: center;
  h2 {
    margin: 0;
  }
`;

export const SearchInput = formInput(function (props: Props) {
  const { suggestions = [], onChange, onSearch, ...otherProps } = props;
  const id = uniqueId();

  return (
    <Container {...otherProps}>
      <Grid>
        <SearchTextInput
          {...otherProps}
          value={props.value ?? ''}
          list={id}
          type="search"
          onChange={(event) => {
            onChange?.(event, event.currentTarget.value);
          }}
          onKeyPress={(k) => k.key === 'Enter' && onSearch && onSearch()}
        />
        <datalist id={id}>
          {suggestions.map((opt, i) => (
            <option key={i}>{opt}</option>
          ))}
        </datalist>
        <h2>
          <IoSearch onClick={() => onSearch && onSearch()} />
        </h2>
      </Grid>
    </Container>
  );
});
