import React, { ReactElement, useState } from 'react';
import { Grid } from '../Grid';
import { Layout } from '../Layout';
import TabTitle from './TabTitle';

type Props = {
  children: ReactElement[];
};

const Tabs: React.FC<Props> = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <Layout horizontal gap={0.5}>
        <Grid gap={2} colsTemplate={`repeat(${props.children.length}, auto)`} cols={props.children.length}>
          {props.children.map((item, index) => (
            <TabTitle
              isSelected={selectedTab === index}
              key={index}
              title={item.props.title}
              index={index}
              setSelectedTab={setSelectedTab}
            />
          ))}
        </Grid>
      </Layout>
      {props.children[selectedTab]}
    </>
  );
};

export default Tabs;
