import React from 'react';
import styled from 'styled-components';
import IconMail from '../../kit/Icons/IconMail';
import IconPhone from '../../kit/Icons/IconPhone';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import { IconBase } from 'react-icons';

export function SupportInformation() {
  return (
    <InformationLayout gap={2}>
      <img src={require('../../core/authn/mps-medica-logo.svg')} />
      <Layout gap={3}>
        <Layout>
          <InfoText weight={'bold'} size={'medium'}>
            CUSTOMER SUPPORT
          </InfoText>
        </Layout>
        <Layout gap={4}>
          <Layout gap={1}>
            <Layout horizontal align={'center'}>
              <IconPhone />
              <EmailIdText href="tel:1800003938">
                &nbsp; 1800 003 938
              </EmailIdText>
            </Layout>
            <Layout horizontal align={'center'}>
              <InfoText weight={'100'} size={'medium'}>
                Customer Service Hours: Monday–Friday 8am–7pm (AEST)
              </InfoText>
            </Layout>
            <Layout horizontal align={'center'}>
              <InfoText weight={'100'} size={'medium'}>
                MediSphere Emergency Support: 24 hours 7 days
              </InfoText>
            </Layout>
            <Layout horizontal align={'center'}>
              <InfoText weight={'100'} size={'medium'}>
                eNRMC Prescriber Support: 24 hours 7 days
              </InfoText>
            </Layout>
          </Layout>


          <Layout gap={1}>
            <Layout horizontal align={'center'}>
              <IconMail />
              <InfoText weight={'200'} size={'medium'}>
                &nbsp; Email: &nbsp;
                <EmailIdText href="mailto:customerservice@mpsconnect.com.au">
                  customerservice@mpsconnect.com.au
                </EmailIdText>
              </InfoText>
            </Layout>
            <Layout horizontal align={'center'}>
              <InfoText weight={'100'} size={'medium'}>
                Email Monitored: Monday–Friday 8am–7pm (AEST)
              </InfoText>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </InformationLayout>
  );
}

const InfoText = styled(Text)`
  color: ${(p) => p.theme.backgrounds.default.fg};
`;

const EmailIdText = styled.a`
  text-decoration: none;
  font-size: ${(p) => p.theme.typography.h2.fontSize};
  color: ${(p) => p.theme.backgrounds.default.fg};
`;

const InformationLayout = styled(Layout)`
  grid-template-rows: auto 1fr;
`;
