import React, { useEffect, useState } from 'react';
import { createServiceProvider } from '../../../context/AppServiceProvider';
import { Logger } from '../../../core/logger/logger';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';
import { RoundScheduleItem } from '../../../syncstream/utils/RoundUtils';
import { HSDoseRound } from 'server-openapi';
import { useStore } from '../../../core/storage/hooks/UseStore';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';

export interface RoundScheduleContext {
  set: (round: HSDoseRound | undefined) => void;
  roundSchedule?: RoundScheduleItem[];
}

const logger = new Logger('RoundScheduleProvider');
const [ServiceProvider, useHook] = createServiceProvider<RoundScheduleContext>();

export const useRoundSchedule = useHook;

interface Props {
  children?: React.ReactNode;
}

export const RoundScheduleProvider = (props: Props) => {
  const services = useSyncCenter();
  const apiUtils = useApiUtils();
  const [round, setRound] = useState<HSDoseRound>();
  const [roundSchedule, setRoundSchedule] = useState<RoundScheduleItem[]>();
  const roundStore = useStore(services.rounds.store).store;
  useEffect( () => {
    logger.info('Computing round schedule.');
    if (round) {
      const roundFromStore = roundStore.get(round.clinicalSystemId!); //this ensures that the round from indexed DB is used
      const newSchedule = roundFromStore ? apiUtils.rounds.getScheduleFromRound(roundFromStore) : undefined;
      setRoundSchedule(newSchedule);
    } else {
      setRoundSchedule(undefined);
    }
    logger.info('Completed computing round schedule.');
  }, [round]);

  function set(round: HSDoseRound | undefined): void {
    setRound(round);
  }

  return <ServiceProvider value={{ roundSchedule: roundSchedule, set: set }}>{props.children}</ServiceProvider>;
};
