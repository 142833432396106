import { HSDrug, HSFacility, HSFacilityGroup, HSPatient } from 'server-openapi';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { Layout } from '../../../kit/Layout';
import { DashboardNavbar } from '../DashboardPage/components/DashboardNavbar';
import { IconBack } from '../../../kit/Icons/Back';
import { Text } from '../../../kit/Text';
import { Grid } from '../../../kit/Grid';
import { SearchInput } from '../../../kit/Forms/SearchInput';
import { Select } from '../../../kit/Forms/Select';
import styled from 'styled-components';
import { IAdHocAdministeredDrug, IAdministeredDrug } from '../../../syncstream/utils/DrugUtils';
import { AdministrationCard } from '../components/AdministrationCard';
import { Badge } from '../components/Badge';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';

export interface MedicationDoses {
  key: string;
  facility?: HSFacility;
  patient: HSPatient;
  drug?: HSDrug;
  isCeased: boolean;
  isNimOrPrn: boolean;
  medicationListType: MedicationListType;
  ad: IAdministeredDrug | IAdHocAdministeredDrug;
}

export enum MedicationListType {
  Refused = 1,
  Withheld,
  Prns,
  Nims,
  Other,
  NoStock
}

export function MedicationListLayout(props: {
  facilityGroupId: string;
  reasonText: string;
  facilities: HSFacility[];
  rows: MedicationDoses[];
  facilityGroup?: HSFacilityGroup;
}) {
  const history = useHistory();
  const [filterPatientName, setFilterPatientName] = useState<string>('');
  const [filterWing, setFilterWing] = useState<HSFacility>();

  const facilityOptions = props.facilities.map((facility) => {
    return { label: facility.name ?? 'Unknown', value: facility };
  });
  const apiUtils = useApiUtils();
  const wingLabel = apiUtils.facilityGroups.getFacilityUILabel(parseInt(props.facilityGroupId));
  const residentLabel = apiUtils.facilityGroups.getResidentUILabel(parseInt(props.facilityGroupId));

  return (
    <Layout>
      <DashboardNavbar facilityGroup={props.facilityGroup} />
      <Container gap={1}>
        <Layout horizontal gap={1.5}>
          <IconBack onClick={() => history.push(`/facility-group/${props.facilityGroupId}`)} />
          <Layout horizontal>
            <Text weight={'bold'} size="large" style={{ color: 'white' }}>
              {props.reasonText}
            </Text>
            <Badge>{props.rows.length}</Badge>
          </Layout>
        </Layout>
        <Grid cols={2} colsMobile={1} gap={1}>
          <SearchInput
            onChange={(_, v) => setFilterPatientName(v)}
            value={filterPatientName}
            name="search"
            placeholder={`Search ${residentLabel}`}
            fullWidth
          />
          {facilityOptions.length === 1 ? (
            <WingNameBox>
              <Text>{facilityOptions[0].label}</Text>
            </WingNameBox>
          ) : (
            <Select
              fullWidth
              placeholder={`All ${wingLabel}`}
              name="wing"
              options={facilityOptions}
              value={filterWing}
              onChange={(_, value) => setFilterWing(value)}
            />
          )}
        </Grid>
        {props.rows &&
          filterPatientList(props.rows, filterPatientName, filterWing).map((p) => (
            <AdministrationCard {...p} key={p.key} />
          ))}
      </Container>
    </Layout>
  );
}

function filterPatientList(rows: MedicationDoses[], filterPatientName: string, filterWing?: HSFacility) {
  const patientUtils = useApiUtils().patients;
  return rows.filter((row) => {
    if (filterWing && row.facility && filterWing.hsId !== row.facility.hsId) {
      return false;
    }

    const residentName = patientUtils.getQueryPatientString(row.patient);

    if (filterPatientName !== '' && !residentName.includes(filterPatientName.toUpperCase())) {
      return false;
    }

    return true;
  });
}

const Container = styled(Layout)`
  margin: 1.5rem;
`;

const WingNameBox = styled(Layout)`
  display: flex;
  padding: ${(props) => props.theme.forms.input.padding};
  align-items: center;
  background: white;
`;
