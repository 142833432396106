/* eslint-disable no-console */
export class Logger {
  constructor(private name: string) {}

  info(message: string, ...params: unknown[]) {
    console.info(this.prefix(message), ...params);
  }

  error(message: string, ...params: unknown[]) {
    console.error(this.prefix(message), ...params);
  }

  warn(message: string, ...params: unknown[]) {
    console.warn(this.prefix(message), ...params);
  }

  private prefix(message: string) {
    return `${new Date().toLocaleString()}: ${this.name}: ${message}`;
  }
}
